import React, { useState } from "react"
import { Form } from "semantic-ui-react"
import { useForm } from "react-hook-form"
import Button from "react-bootstrap/Button"
import useRazorpay from "react-razorpay"
import axios from "axios"
import { navigate } from "gatsby"

export default function Nominate() {
  const [uploadedFiles, setUploadedFiles] = useState([])

  const total = 15000 * 1.18

  const Razorpay = useRazorpay()

  const completeRazorpayOrder = order =>
    new Promise(res => {
      const { amount, razorpay_order_id, id } = order
      var options = {
        // key: "rzp_live_sghxYlxlQshHZj", // Enter the Key ID generated from the Dashboard
        key: "rzp_test_xL98of2uJRdFiI",
        amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Acme Corp",
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        order_id: razorpay_order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: function (response) {
          res(response)
        },
        notes: {
          orderId: id,
        },
        theme: {
          color: "#3399cc",
        },
      }
      const rzp1 = new Razorpay(options)
      rzp1.open()
    })

  const handleFileEvent = files => {
    const uploaded = [...uploadedFiles]
    files.some(file => {
      uploaded.push(file)
    })
    setUploadedFiles(uploaded)
  }

  const handleFileChange = e => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)

    handleFileEvent(chosenFiles)
  }

  const {
    register,
    handleSubmit,
    control,
    formState: { data, errors },
  } = useForm()
  const onSubmit = async formData => {
    let fileForm = new FormData()

    uploadedFiles.forEach(file => {
      fileForm.append("files", file)
    })
    let array = []

    try {
      const fileID = await axios
        //   .post(
        //     "http://localhost:1337/api/upload",
        //     fileForm
        //   )
        .post("https://api.csr.skillsscale.in/api/upload", fileForm)

      array = fileID.data.map(v => ({ id: v.id }))
      const payload = {
        data: {
          entity: {
            type: "event-nomination",
            eventId: 1,
          },
          operation: "insert",
          data: {
            full_name: formData.full_name,
            designation: formData.designation,
            company_name: formData.company_name,
            email: formData.email,
            phone_number: formData.phone_number,
            nomination_fee: formData.nomination_fee,
            attachment: array,
            category: formData.category,
            comments: formData.comments,
          },
        },
      }

      const result = await axios

        // .post("http://localhost:1337/api/orders", payload)
        .post("https://api.csr.skillsscale.in/api/orders", payload)
        .then(({ data }) => data.data.attributes)

      console.log(JSON.stringify(result, null, 2))

      const { order: createdOrder } = result
      await completeRazorpayOrder(createdOrder).then(() => {
        navigate("/nominationComplete")
      })
    } catch (error) {
      //   console.log("onSubmit ~ error", error)
    }
  }

  return (
    <>
      <div className="container">
        <h1 className="text-center">Award Nomination Form</h1>
        <ol>
          <li>
            <strong>Step 1:</strong>Select Award Category
          </li>
          <li>
            <strong>Step 2:</strong>Fill the nominatiom form
          </li>
          <li>
            <strong>Step 3:</strong>Submit case study or white paper in any
            format andanysize stating your key achievements along with the form.
            The case study or white paper will be forwarded to the JUry for
            selection. The stronger the Case Study, better the chance of
            winning!! Award Results will be declared one week prior to the event
            & selected winners will be informed accordingly.
          </li>
        </ol>
      </div>
      <div className="row mt-4 pt-4 ">
        <div className="col-md-2"></div>
        <div className="col-8">
          <div className="card shadow-lg">
            <div className="card-body">
              <div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row align-items-center ">
                    <div className="col-md-6">
                      <Form.Field>
                        <label className="mt-4">Full Name</label>
                        <input
                          placeholder="Full Name"
                          className="form-control"
                          type="text"
                          {...register("full_name", {
                            required: true,
                            minLength: 3,
                          })}
                        />
                        {errors.full_name && (
                          <p className="text-danger">PLease check first Name</p>
                        )}
                      </Form.Field>
                    </div>
                    <div className="col-md-6">
                      <Form.Field>
                        <label className="mt-4">Email</label>
                        <input
                          className="form-control"
                          placeholder="Email"
                          type="email"
                          {...register("email", {
                            required: true,
                            pattern:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          })}
                        />
                        {errors.email && (
                          <p className="text-danger">
                            Please check your Email address
                          </p>
                        )}
                      </Form.Field>
                    </div>
                    <div className="col-md-6">
                      <Form.Field>
                        <label className="mt-4">Phone Number</label>
                        <input
                          className="form-control"
                          placeholder="Enter Phone Number"
                          type="number"
                          {...register("phone_number", {
                            required: true,
                            pattern: /^\d{10}$/,
                          })}
                        />
                        {errors.phone_number && (
                          <p className="text-danger">
                            Please check your phone number
                          </p>
                        )}
                      </Form.Field>
                    </div>
                    <div className="col-md-6">
                      <Form.Field>
                        <label className="mt-4">Designation</label>
                        <input
                          placeholder="Enter Designation"
                          className="form-control"
                          type="text"
                          {...register("designation", {
                            required: true,
                            minLength: 3,
                          })}
                        />
                        {errors.designation && (
                          <p className="text-danger">
                            Please check your Designation
                          </p>
                        )}
                      </Form.Field>
                    </div>
                    <div className="col-md-6">
                      <Form.Field>
                        <label className="mt-4">Company Name</label>
                        <input
                          className="form-control"
                          placeholder="Enter Company Name"
                          type="text"
                          {...register("company_name", {
                            required: true,
                            minLength: 3,
                          })}
                        />
                        {errors.company_name && (
                          <p className="text-danger">
                            Please check your company name
                          </p>
                        )}
                      </Form.Field>
                    </div>

                    <div className="col-md-6">
                      <Form.Field>
                        <label className="mt-4">Select Award Category</label>
                        <br />
                        <select
                          className="form-control"
                          {...register("category", {
                            required: true,
                          })}
                        >
                          <option value="">Choose an option</option>
                          <option value="best_esg_practice_LIC">
                            Best ESG Practice - Large Indian Corporate / MNCs
                          </option>
                          <option value="best_esg_practice_SMI">
                            Best ESG Practice - Indian Small & Medium
                            Enterprises
                          </option>
                          <option value="ESG_organization">
                            ESG Enabler of the Year (Organization)
                          </option>
                          <option value="ESG_Individual_consultant">
                            ESG Enabler of the Year (Individual Consultant)
                          </option>
                        </select>
                        {errors.category && (
                          <p className="text-danger">
                            Please check your Category
                          </p>
                        )}
                      </Form.Field>
                    </div>
                    <div className="col-md-6">
                      <Form.Field>
                        <label className="mt-4">Nomination fee</label>
                        <br />
                        <input
                          className="form-control"
                          disabled
                          {...register("nomination_fee")}
                          value="15000 + 18% GST"
                        />
                      </Form.Field>
                    </div>
                    <div className="col-md-6">
                      <Form.Field>
                        <label className="mt-4">
                          Upload appropriate document(s)
                        </label>
                        <input
                          type="file"
                          // name="files"
                          multiple
                          className="form-control"
                          {...register("attachment", {
                            required: true,
                            onChange: handleFileChange,
                          })}
                        />
                        {errors.attachment && (
                          <p className="text-danger">
                            Please attachment document
                          </p>
                        )}
                        <div className="text-danger">
                          File should be less than 5mb
                        </div>
                      </Form.Field>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="mt-4">Total:{total}</label>
                  </div>

                  <div className="col-md-12">
                    <Form.Field>
                      <label className="mt-4">Any other Comment </label>
                      <textarea
                        className="form-control"
                        placeholder="Enter a Message"
                        {...register("comments", {})}
                      ></textarea>
                    </Form.Field>
                  </div>

                  <div className="col-md-12">
                    <Button className="button mt-4" type="submit">
                      Submit
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>
    </>
  )
}
